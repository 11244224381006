.header {
	display: flex;
	justify-content: space-between;

	margin-bottom: 16px;
}

.headerTitle {
	color: var(--darkBlue);

	font-size: 20px;
	font-weight: 700;
	line-height: 40px;
}

.sidebarChat {
	width: 100%;
	max-width: 352px;
	height: calc(100vh - 196px);

	border-radius: 4px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.sectionRightWrap {
	width: 100%;
	height: calc(100vh - 196px);

	border-radius: 4px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

:global .mantine-Input-input {
	height: 40px;

	border: 1px solid var(--areaWhite);
	border-radius: 7px;

	background: var(--lightWhite);
}

.sidebarChatWrapMessage {
	width: 100%;
	overflow-y: scroll;
}

.sidebarChatWrapMessage::-webkit-scrollbar {
	overflow: hidden;

	width: 0;
}

.sidebarChatWrapItem {
	width: 100%;
	padding: 8px 16px 11px;
	cursor: pointer;
	&.active {
		background: #F5F8FB;
	}
}

.sidebarChatWrapItem:hover {
	background: var(--smokyWhite);
}

.sidebarChatTopRow {
	display: flex;
	flex-wrap: nowrap;

	width: 100%;
}

.sidebarChatCamarote {
	flex-basis: 50%;

	color: var(--darkBlue);

	font-size: 10px;
	font-weight: 400;
	line-height: 20px;
}

.sidebarChatTiempo {
	flex-basis: 50%;

	text-align: right;

	opacity: 0.6;
	color: var(--darkBlue);

	font-size: 10px;
	font-weight: 400;
	line-height: 20px;
}

.sidebarChatUserName {
	color: var(--darkBlue);

	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	&.active {
		color: var(--secendary);
	}
}

.sidebarChatMessage {
	display: block;
	overflow: hidden;

	width: 240px;

	white-space: nowrap;

	text-overflow: ellipsis;

	opacity: 0.7;

	color: #052039;

	font-size: 12px;

	font-weight: 400;
	line-height: 20px;
}

.sidebarChatDownRow {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	width: 100%;
}

.sidebarChatNotification {
	margin-left: 8px;
	padding: 4px 8px;

	color: var(--darkBlue);
	border-radius: 16px;
	background: var(--areaWhite);

	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
}

.sectionRightWrap::-webkit-scrollbar {
	width: 0;
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	width: 100%;
	padding: 10px 12px;

	cursor: pointer;
	transition: all 0.5s;
	text-align: center;

	border: none;
	border-radius: 3px;

	font-size: 14px;

	font-weight: 600;
	line-height: 20px;
}

.primary {
	color: var(--white);
	background-color: var(--darkBlue);
}

.primary:hover {
	background-color: #0D4983;
}

.ghost {
	color: #000;
	border: none;
	background-color: white;

	font-weight: 600;
}

.ghost:hover {
	background-color: #E9E8E8;
}

.secondary {
	color: white;
	background-color: #00B9E7;
}

.secondary:hover {
	background-color: #77D5EC;
}

.orange {
	color: white;
	background-color: var(--orange);
}

.orange:hover {
	background-color: #D06602;
}

.sm {
	width: 114px;
}

.md {
	width: 156px;
}

.lg {
	width: 227px;
}

.restaurants {
	padding: 30px 20px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	margin: 24px 0 8px;

	color: var(--darkBlue);

	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
}

.tabs {
	padding: 0 16px;

	border: 1px solid var(--gray);
	border-radius: 8px;
}

.addTabButton:disabled {
	margin-left: 8px;

	color: black;

	font-size: 14px;
	font-weight: 600;
}

.addTab {
	display: flex;
	align-items: center;
	justify-content: center;
}

.headerSelectRestAndBars :global .mantine-Select-defaultVariant {
	max-width: 230px;
	height: 29px;
	min-height: 29px;
	padding: 0 36px 0 0;

	color: var(--darkBlue);
	border: none;

	font-size: 18px;
	font-weight: 700;
	line-height: 160%;
}

:global .mantine-Tabs-body {
	padding-top: 0;
}

.programDayContent {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.programDayTextDownload {
	color: var(--textBlack);

	line-height: 24px;
}

.programDayTextLink {
	width: 280px;

	color: var(--secendary);

	line-height: 24px;
}

/* календарь */

.programDayCalendar {
	overflow: hidden;
	justify-content: center;

	width: 100%;
	max-width: 328px;
	padding: 16px;

	border-radius: 8px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevelIcon {
	display: none;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevel {
	height: 28px;

	color: var(--darkBlue);

	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeader {
	margin-bottom: 10px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl {
	width: 28px;
	height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl svg {
	width: 24px;
	height: 24px;

	color: var(--darkBlue);
}

.programDayCalendar :global .mantine-Calendar-day {
	width: 40px;
	height: 36px;

	color: var(--darkBlue);

	line-height: 36px;
}

.programDayCalendar :global .__mantine-ref-selected:not(:disabled) {
	background-color: var(--darkBlue);
}

:global .mantine-Calendar-weekdayCell:nth-child(6) .mantine-Calendar-weekday,
:global .mantine-Calendar-weekdayCell:nth-child(7) .mantine-Calendar-weekday {
	color: var(--weekDayRed);
}